import { Injectable } from '@angular/core';
import { JWT_REFRESH_TOKEN_STORAGE, JWT_ACCESS_STORAGE, USER_DATA_STORAGE } from '../../config';
import { USER_ROLES, UserModel } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


export interface UserResponse {
  refreshToken: string;
  accessToken: string;
  admin: UserModel;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private refreshToken;
  private accessToken;
  public user$: BehaviorSubject<UserModel>;


  constructor(
    private http: HttpClient) {
    this.initService();
  }

  /**
   * init constructor call
   * Set access and refresh token
   * set user default from local storage, if no user in LS set as undefined
   */
  private initService(): void {
    this.accessToken = localStorage.getItem(JWT_ACCESS_STORAGE);
    this.refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE);
    this.user$ = new BehaviorSubject<UserModel>(localStorage.getItem(USER_DATA_STORAGE)
      ? JSON.parse(localStorage.getItem(USER_DATA_STORAGE))
      : undefined);
  }

  /**
   * Log into app
   * @param email
   * @param password
   */
  public logIn(email: string, password: string): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/login/admin`,
      {email, password}).pipe(tap(
      response => {
        this.updateUserDataWithTokens(response.admin,
          response.accessToken,
          response.refreshToken);
      }
    ));
  }

  /**
   * request to reset password
   * @param email: use email to send link to set new password
   */
  public resetPasswordRequest(email): Observable<any> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/admin/request`,
      {email});
  }

  public setNewPassword(token, newPassword): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/admin/confirm`,
      {token, newPassword}).pipe(
      tap(res => {
        this.updateUserDataWithTokens(res.admin, res.accessToken, res.refreshToken);
      })
    );
  }

  /**
   * update access token in app
   * @param newToken: new accessToken
   */
  private updateAccessToken(newToken: string): void {
    this.accessToken = newToken;
    localStorage.setItem(JWT_ACCESS_STORAGE, newToken);
  }

  /**
   * update refreshToken in app
   * @param newToken: new refreshToken
   */
  private updateRefreshToken(newToken: string): void {
    this.refreshToken = newToken;
    localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, newToken);
  }

  /**
   * Update user data in app
   * @param user: new user
   */
  public updateUserData(user: UserModel): void {
    this.user$.next(user);
    localStorage.setItem(USER_DATA_STORAGE, JSON.stringify(user));
  }

  /**
   * Update user information and tokens
   *
   * @param user: user data, UserModel
   * @param accessToken: accessToken
   * @param refreshToken: refreshToken
   */

  private updateUserDataWithTokens(user, accessToken, refreshToken): void {
    this.updateUserData(user);
    this.updateAccessToken(accessToken);
    this.updateRefreshToken(refreshToken);
  }

  /**
   * logout user from app
   */
  public logOutUser(): void {
    localStorage.removeItem(JWT_ACCESS_STORAGE);
    localStorage.removeItem(JWT_REFRESH_TOKEN_STORAGE);
    localStorage.removeItem(USER_DATA_STORAGE);
    this.refreshToken = null;
    this.accessToken = null;
    this.user$.next(null);
  }

  /**
   * refresh Tokens in app
   */
  public refreshTokens(): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/refresh/admin`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      // map((res: any) => res.data),
      tap((res) => {
        this.updateUserDataWithTokens(res.admin, res.accessToken, res.refreshToken);
      }));
  }

  /**
   * get access token from app
   */
  public getAccessToken(): string {
    return this.accessToken;
    // return localStorage.getItem(JWT_TOKEN_STORAGE);
  }

  /**
   * get refresh token from app
   */
  public getRefreshToken(): string {
    return this.refreshToken;
  }

  isDeliveryGuy(): boolean {
    const user: UserModel = JSON.parse(localStorage.getItem(USER_DATA_STORAGE));
    if (!user) {
      return false;
    }
    return !!user.roles.includes(USER_ROLES.ROLE_DELIVERY);
  }

  isAdmin(): boolean {
    const user: UserModel = JSON.parse(localStorage.getItem(USER_DATA_STORAGE));
    if (!user) {
      return false;
    }
    return !!user.roles.includes(USER_ROLES.ROLE_ADMIN);
  }
}
