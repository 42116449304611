import { LanguageModel } from './language';
import { AddressModel } from './address';
import { CompanyModel } from './company';

export interface UserModel {
  publicId: string;
  email: string;
  name: string;
  surname: string;
  phoneNumber: PhoneNumberModel;
  language: LanguageModel;
  isCompany: boolean;
  isActive: boolean;
  created: string;
  billingAddress: AddressModel;
  company: CompanyModel;
  roles: USER_ROLES[];
}


export enum USER_ROLES {
  ROLE_DELIVERY = 'ROLE_DELIVERY',
  ROLE_ADMIN = 'ROLE_ADMIN'
}


export interface PhoneNumberModel {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}
