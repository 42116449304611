import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbMomentDateModule } from '@nebular/moment';
import { NbDatepickerModule, NbThemeModule, NbToastrModule } from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RefreshHandlerInterceptor } from './shared/interceptors/refresh-handler.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ToastHandlerInterceptor } from './shared/interceptors/toast-handler.interceptor';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.backendUrl}/translation/`, '');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbDatepickerModule.forRoot(),
    NbMomentDateModule,
    NbThemeModule.forRoot({name: 'kvety'}),
    NbToastrModule.forRoot({}),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }
    ),
    NbEvaIconsModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'sk-SK'},
    {provide: HTTP_INTERCEPTORS, useClass: RefreshHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ToastHandlerInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
