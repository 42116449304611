import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { DeliveryGuyGuard } from './shared/guards/delivery-guy.guard';
import { AdminGuard } from './shared/guards/admin.guard';


const routes: Routes = [{
  path: '',
  redirectTo: 'admin',
  pathMatch: 'full',
},
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    canActivateChild: [AuthGuard, AdminGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)

  }, {
    path: 'delivery-guy',
    canActivate: [AuthGuard, DeliveryGuyGuard],
    canActivateChild: [AuthGuard, DeliveryGuyGuard],
    loadChildren: () => import('./modules/delivery-guy/delivery-guy.module').then(m => m.DeliveryGuyModule)

  }, {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)

  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
