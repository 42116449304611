import { Directive, OnDestroy } from '@angular/core';

@Directive()
export class SubscriberComponent implements OnDestroy {
  public subs = [];


  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
