import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastHandlerService {

  constructor(private nbToastrService: NbToastrService,
              private translateService: TranslateService) {
  }

  /**
   * show main response error and all validation errors
   * @param error http error
   */
  public showErrorToastsResponse(error: HttpErrorResponse): any {
    if (error.error && error.error.errors) {
      const validateErrors = Object.keys(error.error.errors);
      const objectOfValidateErrors = {};
      for (const validateError of validateErrors) {
        if (Array.isArray(error.error.errors[validateError])) {
          error.error.errors[validateError].forEach(
            singleError => {
              this.nbToastrService.danger(singleError);
            }
          );
          objectOfValidateErrors[validateError] = error[validateError][0];
        } else {
          this.nbToastrService.danger(this.translateService.instant(`be.error.${error.error.errors[validateError].code}`));
        }
      }
    } else {
      this.handleClassicError(error);
    }
  }

  /**
   * handle classic errors from be
   * @param error
   *
   */
  private handleClassicError(error): void {
    if (error.error.code) {
      this.nbToastrService.danger(this.translateService.instant(`be.error.${error.error.code}`));
    } else {
      this.nbToastrService.danger(error.error.message);
    }
  }


  /**
   * Show success response backend message
   */
  public showSuccessToastsResponse(): any {
    this.nbToastrService.success(
      'Successful'
    );
  }
}
