import { CurrencyModel } from './shared/models/currency';

export const JWT_ACCESS_STORAGE = 'e-kvety-backoffice-access-token';
export const JWT_REFRESH_TOKEN_STORAGE = 'e-kvety-backoffice-refresh-token';
export const USER_DATA_STORAGE = 'e-kvety-backoffice-user';
export const FLWR_LANGUAGE = 'e-kvety-backoffice-language';


export const PATTERNS = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/
};

export const euroCurrency: CurrencyModel = {
  symbol: '€',
  code: 'eur',
  name: 'euro',
  proportion: 100
};

export const dateFormat = {
  dateFormat: 'DD.MM.YYYY',
  timeFormat : 'HH:mm:ss',
};
