import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { FLWR_LANGUAGE } from '../../config';
import { SubscriberComponent } from '../classes/subscriber-component';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceService extends SubscriberComponent {


  constructor(private translateService: TranslateService,
              private languageService: LanguageService) {
    super();
  }


  public initAppLanguage() {
    /**
     * setting app default language language
     */
    // first set default language of app
    this.translateService.setDefaultLang('en');
    let supportedLanguages;
    // if user have no information about language in local storage
    if (!localStorage.getItem(FLWR_LANGUAGE)) {
      // get supported languages from BE
      this.subs.push(this.languageService.getLanguages().subscribe(
        lang => {
          supportedLanguages = lang.map(singleLang => {
            return singleLang.code;
          });
          // check if browser default language is supported by app and set app language
          if (supportedLanguages.some(singleLang => {
            if (this.translateService.getBrowserLang() === singleLang) {
              return true;
            }
          })) {
            // set language from browser and use it
            localStorage.setItem(FLWR_LANGUAGE, this.translateService.getBrowserLang());
            this.translateService.use(this.translateService.getBrowserLang());
          } else {
            // if browser lang no supported by app set and use default lang
            localStorage.setItem(FLWR_LANGUAGE, this.translateService.defaultLang);
            this.translateService.use(this.translateService.defaultLang);
          }
        }));
    } else {
      // TODO: find out language from request !
      // if have lang in localstorage, use it
      this.translateService.use(localStorage.getItem(FLWR_LANGUAGE));
    }
  }
}
